import styled from "@emotion/styled";
import { IonContent } from "@ionic/react";
import type { FC } from "react";
import React, { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import translate from "src/lib/translate";
import LoadingCubit, { LoadingKey } from "src/state/LoadingCubit/LoadingCubit";
import { useBloc } from "src/state/state";
import Loader from "src/ui/components/Loader/Loader";
import type { SignupCustomPageParameters } from "src/ui/components/SignupCustomContent/SignupCustom.types";
import SignupCustomCampaign from "src/ui/components/SignupCustomContent/SignupCustomCampaign";
import SignupCustomBloc from "src/ui/components/SignupCustomContent/state/SignupCustomBloc";
import { useBlocNext } from "@blac/react";
import { CenterEl, ErrorPageLayout } from "../SentryBoundary/ErrorPage";
import Translate from "../Translate/Translate";

const ContentWrap = styled.div`
  label: ContentWrap;
  display: grid;
  place-items: center;
  --p-top: calc(76px);
  --p-bottom: 2rem;
  min-height: 100svh;
  padding: var(--p-top) 0 0 0;
  box-sizing: border-box;

  & > nine-bg {
    flex: 1;
  }

  .fade-wrap {
    width: 100%;
    height: 100%;
  }
`;

const IonContentStyled = styled(IonContent)`
  background-color: var(--color-cream);
  height: 100vh;
  height: 100svh;

  transition: padding-bottom 0.5s cubic-bezier(0.38, 0.7, 0.125, 1);

  &:has(:focus-within) {
    padding-bottom: var(--stored-keyboard-height, 0px);
  }
`;

const SignupCustomContent: FC = () => {
  const navigate = useNavigate();
  const parameters = useParams<SignupCustomPageParameters>();
  const [
    { campaign, step, currentStepValid, isAvailable },
    {
      currentStep,
      campaignNameIsValid,
      campaignPrettyName,
      isDemoFunnel,
      getActiveCampaign
    }
  ] = useBlocNext(SignupCustomBloc);
  const scrollRef = React.useRef<HTMLIonContentElement>(null);
  const [loadingState, { isLoading }] = useBloc(LoadingCubit);
  const checkingStep = useMemo(
    () => isLoading(LoadingKey.checkCustomSignupStepDataComplete),
    [loadingState]
  );

  const textContext = getActiveCampaign().availabilityChecks?.textContext ?? "";

  // redirect when step changes
  useEffect(() => {
    if (!currentStep) {
      return;
    }

    const path = `${isDemoFunnel ? "/demo" : ""}/signup/${campaign}/${step}`;

    if (step === parameters.step) {
      return;
    }

    navigate(path, {
      replace: !parameters.step
    });
  }, [currentStep, parameters, isDemoFunnel]);

  useEffect(() => {
    if (scrollRef.current) {
      window.nineConfig = {
        ...window.nineConfig,
        mainScrollTarget: scrollRef.current
      };
    }
    return () => {
      window.nineConfig = {
        ...window.nineConfig,
        mainScrollTarget: document.body
      };
    };
  }, []);

  useEffect(() => {
    if (!campaignNameIsValid) {
      window.location.replace("/signup/universal");
    }
  }, [campaignNameIsValid]);

  return (
    <IonContentStyled ref={scrollRef} scrollEvents>
      <ContentWrap>
        <Helmet>
          {currentStep && (
            <title>
              {translate("page_name")} - {campaignPrettyName} -{" "}
              {currentStep.name}
            </title>
          )}
        </Helmet>

        {(checkingStep || typeof isAvailable === "undefined") && (
          <Loader fixed overContent background fullPage />
        )}
        {currentStepValid && isAvailable === false && (
          <ErrorPageLayout>
            <div>
              <CenterEl>
                <nine-heading>
                  <nine-spacer s="xs"></nine-spacer>
                  <h4 className="">
                    <Translate
                      msg="funnel.unavailable.title"
                      variables={{
                        context: textContext
                      }}
                    />
                  </h4>
                  <nine-spacer s="md"></nine-spacer>
                  <p className="m0 color-c-60">
                    <Translate
                      msg="funnel.unavailable.description"
                      variables={{
                        context: textContext
                      }}
                    />
                  </p>
                </nine-heading>
              </CenterEl>
            </div>
          </ErrorPageLayout>
        )}

        {currentStepValid && isAvailable === true && <SignupCustomCampaign />}
      </ContentWrap>
    </IonContentStyled>
  );
};

export default SignupCustomContent;

import { useBlocNext } from "@blac/react";
import type { FC } from "react";
import React, { useRef } from "react";
import CustomQuestionnaire from "src/ui/components/CustomQuestionnaire/CustomQuestionnaire";
import type {
  SignupCustomStep,
  SignupCustomStepQuestionnaire as SignupCustomStepQuestionnaireType
} from "src/ui/components/SignupCustomContent/SignupCustom.types";
import { SignupCustomStepType } from "src/ui/components/SignupCustomContent/SignupCustom.types";
import SignupCustomBloc from "../state/SignupCustomBloc";

const SignupCustomStepQuestionnaireContent: FC<{
  step: SignupCustomStepQuestionnaireType;
  onStepCompleted: (
    step: SignupCustomStepQuestionnaireType
  ) => Promise<boolean> | boolean;
}> = ({ step, onStepCompleted }) => {
  const [{ stepForced }] = useBlocNext(SignupCustomBloc);
  const [showQuestionnaire, setShowQuestionnaire] = React.useState(true);
  const completed = useRef({ lastStep: false, dataSent: false });

  const handleSubmit = () => {
    if (completed.current.lastStep && completed.current.dataSent) {
      setShowQuestionnaire(false);
      void onStepCompleted(step);
    }
  };

  const handleDataSent = () => {
    completed.current.dataSent = true;
    handleSubmit();
  };
  const handleLastStepCompleted = () => {
    completed.current.lastStep = true;
    handleSubmit();
  };

  return (
    <>
      <nine-center
        style={{
          opacity: showQuestionnaire ? 1 : 0
        }}
      >
        <CustomQuestionnaire
          useRouting
          forceRepeat={stepForced}
          onDataSent={handleDataSent}
          onLastStepCompleted={handleLastStepCompleted}
          id={step.questionnaireId}
          resetScrollOnEachStep
          answerOverrides={step.answerOverrides}
          filterAnswerOptions={step.filterAnswerOptions}
        />
      </nine-center>
    </>
  );
};

const SignupCustomStepQuestionnaire: FC<{
  step: SignupCustomStep;
  onStepCompleted: (step: SignupCustomStep) => Promise<boolean> | boolean;
}> = ({ step, onStepCompleted }) => {
  const isQuestionnaire = step.type === SignupCustomStepType.Questionnaire;
  return isQuestionnaire ? (
    <SignupCustomStepQuestionnaireContent
      step={step}
      onStepCompleted={onStepCompleted}
    />
  ) : (
    <></>
  );
};

export default SignupCustomStepQuestionnaire;

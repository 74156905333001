import type { TaskStatus } from "@9amhealth/wcl";
import { useBloc } from "@blac/react";
import styled from "@emotion/styled";
import clsx from "clsx";
import React, { FC, ReactElement, ReactNode, useEffect } from "react";
import { APP_CONTENT_WIDTH, TOP_NAV_HEIGHT } from "src/constants/layout";
import Footer from "src/ui/components/Footer/Footer";
import SignupCustomBloc from "src/ui/components/SignupCustomContent/state/SignupCustomBloc";
import WithBackground from "../WithBackground/WithBackground";

const Stacker = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;

  &.no-footer-content {
    --pad-bottom: 14em;
    min-height: calc(
      100vh - var(--success-header-height, ${TOP_NAV_HEIGHT}px) - var(
          --pad-bottom
        )
    );
    padding-bottom: var(--pad-bottom);
  }
`;

const Front = styled.div`
  position: relative;
  z-index: 2;
  max-width: ${APP_CONTENT_WIDTH}px;
  margin: 0 auto;
`;

const Back = styled.div`
  z-index: 1;

  .no-footer-content & {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
`;

interface ButtonAsObject {
  label: string;
  onClick?: () => void;
}
export interface SignupSuccessPageProps {
  steps?: {
    status: TaskStatus;
    title: ReactNode | string;
    subtitle?: ReactNode | string;
    content?: ReactNode | string;
    show?: () => boolean;
  }[];
  icon?: ReactElement;
  title?: ReactNode | string;
  subtitle?: ReactNode | string;
  info?: {
    title?: ReactNode | string;
    content?: ReactNode | string;
  };
  button?: ButtonAsObject | ReactNode;
  footer?: {
    title?: string;
    button?: ButtonAsObject;
  };
  titleSpacer?: boolean;
}

const SignupSuccessPage: FC<SignupSuccessPageProps> = (props) => {
  const [, { setProgressBarVisibility }] = useBloc(SignupCustomBloc);
  const { info = {}, footer } = props;

  useEffect(() => {
    setProgressBarVisibility(false);
  }, []);

  return (
    <WithBackground variant="animate-primary" fixed style={{ height: "100%" }}>
      <Stacker
        className={clsx({
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          "no-footer-content": !(footer?.title || footer?.button)
        })}
      >
        <Front>
          <nine-spacer s="xl"></nine-spacer>
          <nine-heading>
            <h3>{props.title}</h3>
            <nine-spacer s="xs"></nine-spacer>
            <p className="m0 color-c-80">{props.subtitle}</p>
          </nine-heading>

          {props.titleSpacer !== false && <nine-spacer s="xl"></nine-spacer>}

          <nine-info-section>
            {info.title && (
              <p slot="title" className="m0 strong">
                {info.title}
              </p>
            )}

            <div slot="content">{info.content}</div>
          </nine-info-section>
        </Front>

        <Back>
          <Footer hideSocials title={footer?.title} button={footer?.button} />
        </Back>
      </Stacker>
    </WithBackground>
  );
};

export default SignupSuccessPage;
